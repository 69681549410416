// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';
import { Box, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Close, Minimize } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as SvgMaximize } from 'assets/maximize.svg';
import { ReactComponent as SvgMinimize } from 'assets/minimize.svg';
import { useTranslation } from 'react-i18next';
import { Rnd } from 'react-rnd';

import windowStore, {
  getWindow,
  setFocus,
  hideWindow,
  closeWindow,
} from 'store/windowStore';
import { WindowContextValueItem, SizePosType } from '../../store/types';

const sizeDefault = { width: 938, height: 630 };
const defaultX = (window.innerWidth - sizeDefault.width) / 2;
const defaultY = (window.innerHeight - sizeDefault.height) / 2;
const defaultSizePos = {
  x: defaultX,
  y: defaultY < 18 ? defaultY : 18,
  width: sizeDefault.width,
  height: sizeDefault.height,
};

const showWindow = (id: string) => {
  setFocus(id);
};

const AppWindowContainerHeader: React.FC<{
  win: WindowContextValueItem;
  isMaximize: boolean;
  focused: string;
  onMaximize(bool: boolean): void;
}> = ({ win, isMaximize, focused, onMaximize }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { id, active, title } = win;

  return (
    <div
      className={cx(
        'drag-handle',
        classes.header,
        id === focused ? classes.headerFocused : ''
      )}
    >
      <Box
        sx={{
          flex: '1 1 0%',
        }}
        onMouseDownCapture={() => showWindow(win.id)}
      >
        <Typography align="center" variant="subtitle2">
          {title}
        </Typography>
      </Box>
      <Grid container style={{ width: 'auto' }} spacing={2}>
        <IconButton onClick={() => hideWindow(id)} size="small">
          <Minimize viewBox="0 6 24 24" />
        </IconButton>
        <IconButton onClick={() => onMaximize(!isMaximize)} size="small">
          {isMaximize ? (
            <SvgMinimize width="24" height="14" />
          ) : (
            <SvgMaximize width="24" height="14" />
          )}
        </IconButton>
        <IconButton onClick={() => closeWindow(id)} size="small">
          <Close />
        </IconButton>
      </Grid>
    </div>
  );
};

export default function AppWindowContainer({
  id,
  maximized = true,
  initial = defaultSizePos,
}) {
  const { classes, cx } = useStyles();
  const contentRef = useRef(null);
  const { width, height, x = defaultSizePos.x, y = defaultSizePos.y } = initial;

  const state = useSnapshot(windowStore);
  const focused = state.focused;

  const win = getWindow(id);
  const active = win?.active;

  const [isMaximize, setIsMaximize] = useState(maximized);
  const [sizePos, setSizePos] = useState({ width, height, x, y });
  const prevSizePos = useRef<typeof sizePos>();

  const event = new CustomEvent('appwindowcontrainerresize', {
    bubbles: true,
    detail: {
      id,
    },
  });

  useEffect(() => {
    setSizePos({ x, y, width, height });
  }, [x, y, width, height]);

  useEffect(() => {
    if (isMaximize) {
      if (prevSizePos.current === undefined) {
        prevSizePos.current = { ...defaultSizePos, width, height };
      }

      setSizePos((prev) => {
        const width = window.innerWidth;
        const height = window.innerHeight - 37; // To offset for the taskbar
        const newSizePos = {
          x: 0,
          y: 0,
          width,
          height,
        };

        if (prevSizePos.current === undefined) {
          prevSizePos.current = defaultSizePos;
        } else {
          const update = prev.width !== width || prev.height !== height;
          prevSizePos.current = prev;

          if (update) {
            win.onUpdate?.({
              width,
              height: height - 37, // To offset for the header
            });
          }
        }

        return newSizePos;
      });
    } else if (prevSizePos.current) {
      setSizePos(prevSizePos.current);
      win.onUpdate?.({
        width: prevSizePos.current.width,
        height: prevSizePos.current.height - 37, // To offset for the header
      });
    } else {
      // Initial load
      win.onUpdate?.({
        width: sizePos.width,
        height: sizePos.height,
      });
    }

    // All these doesn't work. Figure it out later
    // contentRef?.current?.dispatchEvent(event);
    // contentRef?.current?.ownerDocument.dispatchEvent(event);
    // document.dispatchEvent(event);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMaximize]);

  const onResize = (e, direction, ref, delta, position) => {
    const width = parseInt(ref.style.width);
    const height = parseInt(ref.style.height);

    setSizePos({ ...position, width, height });

    win.onUpdate?.({
      width: contentRef.current.clientWidth,
      height: contentRef.current.clientHeight,
    });

    ref.dispatchEvent(event);
  };

  const onDrag = (e, d) => {
    setSizePos((prev) => ({ ...prev, x: d.x, y: d.y }));
  };

  const onMaximize = (x: boolean) => {
    setFocus(id);
    setIsMaximize(x);
  };

  if (!win) {
    return null;
  }

  return (
    <Rnd
      style={{
        zIndex: win.zIndex,
        opacity: active ? 1 : 0,
        visibility: active ? '' : 'hidden',
      }}
      default={defaultSizePos}
      size={{ width: sizePos?.width, height: sizePos?.height }}
      position={{ x: sizePos?.x, y: sizePos?.y }}
      onDragStop={onDrag}
      onResizeStop={onResize}
      dragHandleClassName="drag-handle"
      //bounds="window"
    >
      <div
        style={{
          margin: 0,
          width: '100%',
          height: '100%', // size?.height, // '100%',
          border: '1px solid rgb(0 0 0 / 12%)',
          // pointerEvents: win.active ? 'auto' : 'none',
          display: 'flex',
          flexDirection: 'column',
        }}
        className={cx(classes.rnd, win.focused && classes.rndFocused)}
      >
        <AppWindowContainerHeader
          win={win}
          focused={focused}
          isMaximize={isMaximize}
          onMaximize={onMaximize}
        />
        <div
          style={{
            flex: 1,
            /// height: size?.height - 37,
          }}
          ref={contentRef}
          className={classes.content}
          onMouseDownCapture={() => showWindow(win.id)}
        >
          {win.element}
        </div>
      </div>
    </Rnd>
  );
}

const useStyles = makeStyles()((theme) => ({
  header: {
    height: 37, // theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    margin: 0,
    backgroundColor: '#e6e6fa',
  },
  headerFocused: {},
  content: {
    overflow: 'hidden',
    position: 'relative',
  },
  rnd: {
    position: 'absolute',
    transition: 'opacity .2s ease',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.12)',
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    background: theme.palette.grey[100],
    display: 'inline-block',
  },
  rndFocused: {
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
  },
  // '@global': {
  //   '.react-resizable-handle-se': {
  //     zIndex: 1,
  //   },
  // },
}));
