import { info, error } from '@knowins/notifier';
import LanguageControl from 'components/Language/LanguageControl';
import { updateLocalStorage } from 'helper/utils';

export const throwIfGatewayTimeout = (e) => {
  if (e.statusText === 'Gateway Timeout') {
    error('status.gateway_timeout');
  } else {
    console.error('Debug:', e);
  }
  throw e;
};

export const checkSessionTimeout = (res) => {
  if (
    (typeof res === 'object' &&
      res.success === false &&
      res.message === 'status.session_expired') ||
    (typeof res?.data?.result === 'object' &&
      res?.data?.result?.success === false &&
      res?.data?.result?.message === 'status.session_expired')
  ) {
    info(res.message || res?.data?.result?.message, {
      fn: () => {
        updateLocalStorage({ login: false });
        window.location.reload();
      },
    });

    return { data: { result: [] } };
  }

  return res;
};

const writer = {
  type: 'json',
  writeAllFields: true,
  allDataOptions: {
    persist: true,
  },
};

const reader = {
  type: 'json',
  messageProperty: 'message',
  totalProperty: 'totalCount',
  rootProperty: function (data) {
    checkSessionTimeout(data);
    const records = data?.data;
    if (Array.isArray(records)) {
      return records;
    }

    return data;
  },
};

export const loadConfig = (data) => {
  // Load common stores that are used across the application
  const { menu, language } = data;
  console.log(`Loading local language... ${language}`);

  LanguageControl.fetch(language).then(() => {
    console.log(`Creating common stores`);
    MoneFox.controller.MainController.createCommonStores();
  });

  const tree = Ext.getStore('TreeCommands');
  Ext.create('Ext.data.TreeStore', {
    model: 'MoneFox.model.TreeCommand',
    storeId: 'TreeCommands',
    rootVisible: true,
    // defaultRootProperty: 'children',
    root: {
      expanded: true, // IMPORTANT NOTE:
      //   This must be set otherwise the store
      //   will ignore the returned data. However set to true
      //   causing problem with Ext Direct: Cannot resolve directFn"
      children: menu,
    },
  });

  return data;
};

export const proxyConfig = {
  type: 'direct',
  api: {
    read: 'ConnectDB.getConfig',
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    rootProperty: function (data) {
      console.log(
        '..................proxy config got hit.............................'
      );
      return loadConfig(data.data);
    },
  },
};

export const proxyCsAddress = {
  type: 'direct',
  api: {
    create: 'FrmCsAddress.add',
    read: 'FrmCsAddress.get',
    update: 'FrmCsAddress.update',
    destroy: 'FrmCsAddress.delete',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'NAD',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      const records = data.data;

      // A name & address can be both customer and supplier at the same time,
      // So the below logic is obsolete
      const customers = [];
      const suppliers = [];
      // if (records) {
      //   records.forEach((r) => {
      //     if (r.type === 'C' || r.type === 'c') {
      //       customers.push(r);
      //     } else if (r.type === 'S' || r.type === 's') {
      //       suppliers.push(r);
      //     }
      //   });

      //   let state = appStore.legacy.CUS.data;
      //   for (let i = 0; i < customers.length; i++) {
      //     const record = customers[i];
      //     const { addresscode, ...others } = record;
      //     for (let j = 0; j < state.length; j++) {
      //       if (state[j].addresscode == addresscode) {
      //         for (let k in Object.keys(record)) {
      //           state[j][k] = record[k];
      //         }
      //       } else {
      //         state.push(record);
      //       }
      //     }
      //   }

      //   state = appStore.legacy.SUP.data;
      //   for (let i = 0; i < suppliers.length; i++) {
      //     const record = suppliers[i];
      //     const { addresscode, ...others } = record;
      //     for (let j = 0; j < state.length; j++) {
      //       if (state[j].addresscode == addresscode) {
      //         for (let k in Object.keys(record)) {
      //           state[j][k] = record[k];
      //         }
      //       } else {
      //         state.push(record);
      //       }
      //     }
      //   }
      // }

      return records;
    },
  },
};

export const proxyCsAnalysisDefinition = {
  type: 'direct',
  api: {
    create: 'FrmCsAnalysisDefinition.add',
    read: 'FrmCsAnalysisDefinition.get',
    update: 'FrmCsAnalysisDefinition.update',
    destroy: 'FrmCsAnalysisDefinition.delete',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'AND',
    _forced: false,
  },
  reader,
  writer,
};

export const proxyCsAnalysisCode = {
  type: 'direct',
  api: {
    create: 'FrmCsAnalysisCode.add',
    read: 'FrmCsAnalysisCode.get',
    update: 'FrmCsAnalysisCode.update',
    destroy: 'FrmCsAnalysisCode.delete',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'ANC',
    _forced: false,
  },
  reader,
  writer,
};

export const proxyANLYS_T = {
  type: 'direct',
  api: {
    read: 'FrmCsAnalysisCode.getANLYS_T',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'ANLYS_T',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyANLYS_A = {
  type: 'direct',
  api: {
    read: 'FrmCsAnalysisCode.getANLYS_A',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'ANLYS_A',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyANLYS_C = {
  type: 'direct',
  api: {
    read: 'FrmCsAnalysisCode.getANLYS_C',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'ANLYS_C',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyANLYS_S = {
  type: 'direct',
  api: {
    read: 'FrmCsAnalysisCode.getANLYS_S',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'ANLYS_S',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyEXTD_JE = {
  type: 'direct',
  api: {
    read: 'FrmCsAdditionalField.getEXTD_JE',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'EXTD_JE',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyCsContact = {
  type: 'direct',
  api: {
    create: 'FrmCsContact.add',
    read: 'FrmCsContact.get',
    update: 'FrmCsContact.update',
    destroy: 'FrmCsContact.delete',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'COT',
    _forced: false,
  },
  reader,
  writer,
};

export const proxyCsCurrencyCode = {
  type: 'direct',
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'CUR',
    _forced: false,
  },
  api: {
    create: 'FrmCsCurrencyCode.add',
    read: 'FrmCsCurrencyCode.get',
    update: 'FrmCsCurrencyCode.update',
    destroy: 'FrmCsCurrencyCode.delete',
  },
  reader,
  writer,
};

export const proxyCsCurrencyDailyRate = {
  type: 'direct',
  api: {
    create: 'FrmCsCurrencyDailyRate.add',
    read: 'FrmCsCurrencyDailyRate.get',
    update: 'FrmCsCurrencyDailyRate.update',
    destroy: 'FrmCsCurrencyDailyRate.delete',
  },
  reader,
  writer,
};

export const proxyCsCurrencyPeriodRate = {
  type: 'direct',
  api: {
    create: 'FrmCsCurrencyPeriodRate.add',
    read: 'FrmCsCurrencyPeriodRate.get',
    update: 'FrmCsCurrencyPeriodRate.update',
    destroy: 'FrmCsCurrencyPeriodRate.delete',
  },
  reader,
  writer,
};

export const proxyCsCurrentRate = {
  type: 'direct',
  api: {
    read: 'FrmCsCurrencyCode.getCurrentRates',
  },
  reader,
  writer,
};

export const proxyCsCustomer = {
  type: 'direct',
  api: {
    create: 'FrmCsCustomer.add',
    read: 'FrmCsCustomer.get',
    update: 'FrmCsCustomer.update',
    destroy: 'FrmCsCustomer.delete',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'CUS',
    _forced: false,
  },
  reader,
  writer, // TODO: Update Address, Contact, Bank, when customer get updated/add
};

export const proxyCsLookupCode = {
  type: 'direct',
  api: {
    create: 'FrmCsLookupCode.add',
    read: 'FrmCsLookupCode.get',
    update: 'FrmCsLookupCode.update',
    destroy: 'FrmCsLookupCode.delete',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'LKC',
    _forced: false,
  },
  reader,
  writer,
};

export const proxyCsSupplier = {
  type: 'direct',
  api: {
    create: 'FrmCsSupplier.add',
    read: 'FrmCsSupplier.get',
    update: 'FrmCsSupplier.update',
    destroy: 'FrmCsSupplier.delete',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'SUP',
    _forced: false,
  },
  reader,
  writer, // TODO: Update Address, Contact, Bank, when supplier get updated/add
};

export const proxyCsAdditionalField = {
  type: 'direct',
  api: {
    create: 'FrmCsAdditionalField.add',
    read: 'FrmCsAdditionalField.get',
    update: 'FrmCsAdditionalField.update',
    destroy: 'FrmCsAdditionalField.delete',
  },
  extraParams: {
    _module: 'cs',
    _path: 'legacy',
    _key: 'ADF',
    _forced: false,
  },
  reader,
  writer,
};

export const proxyLaChartAccount = {
  type: 'direct',
  api: {
    create: 'FrmLaChartAccount.add',
    read: 'FrmLaChartAccount.get',
    update: 'FrmLaChartAccount.update',
    destroy: 'FrmLaChartAccount.delete',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'COA',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyLaLedgerDefinition = {
  type: 'direct',
  // batchActions: false,
  api: {
    read: 'FrmLaLedgerDefinition.get',
    update: 'FrmLaLedgerDefinition.update',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'LA',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyLaJournalDefinition = {
  type: 'direct',
  api: {
    create: 'FrmLaJournalDefinition.add',
    read: 'FrmLaJournalDefinition.get',
    update: 'FrmLaJournalDefinition.update',
    destroy: 'FrmLaJournalDefinition.delete',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'JND',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: function (data) {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyLaJournalPreset = {
  type: 'direct',
  api: {
    create: 'FrmLaJournalPreset.add',
    read: 'FrmLaJournalPreset.get',
    update: 'FrmLaJournalPreset.update',
    destroy: 'FrmLaJournalPreset.delete',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'JNP',
    _forced: false,
  },
  reader: {
    type: 'json',
    messageProperty: 'message',
    totalProperty: 'totalCount',
    rootProperty: (data) => {
      checkSessionTimeout(data);
      return data.data;
    },
  },
  writer,
};

export const proxyDynamicDataLink = {
  type: 'direct',
  api: {
    create: 'FrmDynamicDataLink.addDynamicDataLink',
    read: 'FrmDynamicDataLink.getDynamicDataLink',
    update: 'FrmDynamicDataLink.updDynamicDataLink',
    destroy: 'FrmDynamicDataLink.delDynamicDataLink',
  },
  reader,
  writer,
};

export const proxyOperator = {
  type: 'direct',
  api: {
    create: 'FrmOperator.add',
    read: 'FrmOperator.get',
    update: 'FrmOperator.update',
    destroy: 'FrmOperator.delete',
  },
  reader,
  writer,
};

export const proxyOperatorRole = {
  type: 'direct',
  api: {
    create: 'FrmOperatorRole.add',
    read: 'FrmOperatorRole.get',
    update: 'FrmOperatorRole.update',
    destroy: 'FrmOperatorRole.delete',
  },
  reader,
  writer,
};

export const proxyOperation = {
  type: 'direct',
  api: {
    read: 'FrmOperation.get',
  },
  reader,
  writer,
};

export const proxyReportDefinition = {
  type: 'direct',
  api: {
    create: 'FrmReportDefinition.add',
    read: 'FrmReportDefinition.get',
    update: 'FrmReportDefinition.update',
    destroy: 'FrmReportDefinition.delete',
  },
  reader,
  writer,
};

export const proxyQueryData = {
  type: 'direct',
  api: {
    read: 'FrmReportDefinition.queryData',
  },
  reader,
};

export const proxyChartDetail = {
  type: 'direct',
  api: {
    create: 'FrmReportChart.add',
    update: 'FrmReportChart.update',
    destroy: 'FrmReportChart.delete',
  },
  reader,
  writer,
};

export const proxyReportGroup = {
  type: 'direct',
  api: {
    create: 'FrmReportGroup.add',
    read: 'FrmReportGroup.get',
    update: 'FrmReportGroup.update',
    destroy: 'FrmReportGroup.delete',
  },
  reader,
  writer,
};

export const proxyLaJournalPrint = {
  type: 'direct',
  api: {
    create: 'FrmPrint.add',
    read: 'FrmPrint.get',
    update: 'FrmPrint.update',
    destroy: 'FrmPrint.delete',
  },
  reader,
  writer,
};

export const proxyLaBank = {
  type: 'direct',
  api: {
    create: 'FrmLaBank.add',
    read: 'FrmLaBank.get',
    update: 'FrmLaBank.update',
    destroy: 'FrmLaBank.delete',
  },
  extraParams: {
    _module: 'la',
    _path: 'legacy',
    _key: 'BNK',
    _forced: false,
  },
  reader,
  writer,
};

export const proxyLocalization = {
  type: 'direct',
  api: {
    read: 'FrmLocalization.getLocalizations',
    create: 'FrmLocalization.addLocalizations',
    update: 'FrmLocalization.updLocalizations',
  },
  reader,
  writer,
};
