import { RouterAPIData } from 'features/common/types';
import { useQuery } from 'react-query';
import axios from 'redaxios';
import { GetEthBalance } from './types';

const action = 'FrmBlockchain';

export function useEthBalanceQuery(email: string) {
  return useQuery<GetEthBalance>(
    [action, 'getEthBalance', email],
    async () => {
      try {
        const res = await axios.post<RouterAPIData<GetEthBalance>>(
          '/api/router.php',
          {
            action,
            method: 'getBalance',
            data: [{ email }],
            type: 'rpc',
            tid: 6,
          }
        );
        return res.data.result.data;
      } catch (e: any) {
        console.log('Exception: %s', e);
        return {
          wallet: '',
          balance: 0,
        };
      }
    },
    { refetchInterval: 10 * 60 * 60 * 1000 }
  );
}
