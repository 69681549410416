import loadable from '@loadable/component';
import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const AppAd = () => {
  return (
    <Box
      style={{
        position: 'absolute',
        left: 38,
        top: 38,
        height: 'calc(100vh - 96px)',
        width: 'calc(100vw - 339px)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage:
          'url(https://static.cnews.fr/sites/default/files/000_1mi8u5_5dfcaf0ed1b9f.jpg)',
      }}
    >
      <Typography style={{ marginTop: -16, paddingLeft: 1, fontSize: 11 }}>
        Advertisements
      </Typography>
    </Box>
  );
};

export default AppAd;
