import loadable from '@loadable/component';
import { warn } from '@knowins/notifier';
import { createWindow } from 'store/windowStore';
import { enact } from 'legacy/classic/src/utils/util';
import { getToDate, getFromDate } from 'legacy/classic/src/utils/period';
import appStore from 'store/appStore';

let index = 1;
// Generate a new record from its model
export const createRecordFromModel = (model, initialCfg = {}) => {
  if (!model || typeof model !== 'function') {
    return false;
  }
  const fields = model.fields;
  if (!fields || fields.length === 0) {
    return false;
  }

  // Let's create a dummy record
  const r = {};
  for (let i = 0; i < fields.length; i++) {
    const f = fields[i];
    if (f.name) {
      if (f.defaultValue !== undefined) {
        r[f.name] = f.defaultValue;
      } else {
        switch (f.type) {
          case 'number':
            r[f.name] = 0;
            break;
          default:
            r[f.name] = '';
        }
      }
    }
  }

  const record = new model(Object.assign(r, initialCfg));
  if (record.idProperty && record.idProperty.length > 2) {
    // Remove the prefix model Cs, La
    record.set(record.idProperty, '');
  }

  return record;
};

export const generateDynamicModel = (prefix, fields, idProperty) => {
  return idProperty
    ? Ext.define(prefix + '.model.' + Ext.id(), {
        extend: 'MoneFox.model.Base',
        fields: fields,
        idProperty: idProperty,
      })
    : Ext.define(prefix + '.model.' + Ext.id(), {
        extend: 'MoneFox.model.Base',
        fields: fields,
      });
};

export const generateDynamicStore = (store) => {
  // Vle debug: change since Ext JS5
  //store.setFields([]);

  // Revert back to Thach version
  store.model.setFields([]);

  if (store.getCount() > 0) {
    var records = store.getAt(0).data; //store.getAt(0).raw;
    for (var propName in records) {
      // BEGIN
      // - store.addField(propName);
      // +
      var field = new Ext.data.Field(propName);
      store.getModel().addFields(field);
      // END
    }
  }
  return store;
};

export const generateDynamicGrid = (store, grid) => {
  grid.headerCt.removeAll();

  // Vle debug: ExtJS 5
  store.setFields([]); // store.model.setFields([]);

  if (store.getCount() > 0) {
    const record = store.getAt(0).data; //store.getAt(0).raw;
    for (let propName in record) {
      const field = new Ext.data.Field(propName);

      if (
        field &&
        field.name &&
        !(field.name === 'id' && record['id'].startsWith('extModel')) // Exclude automatically created model id
      ) {
        store.getModel().addFields(field); // - store.addField(propName);
        const column = {
          text: propName,
          dataIndex: propName,
          filter: true,
        };
        if (propName === 'amount') {
          column.minWidth = 268;
          column.renderer = (num) => Ext.util.Format.number(num, '0,000.00');
        } else {
          column.flex = 1;
        }

        grid.headerCt.insert(
          grid.columns.length,
          Ext.create('Ext.grid.column.Column', column)
        );
      }
    }
    grid.getView().refresh();
    //grid.bindStore(store);
  }
};

export const generateChartConfig = (chart, height, width) => {
  const chartTypeStore = Ext.StoreManager.lookup('ChartTypeStore');
  const chartThemeStore = Ext.StoreManager.lookup('StoreChartTheme');

  var config = null,
    chartFields = [],
    dataField = [];

  chart.store.model.fields.forEach(function (f) {
    if (!f.generated) {
      chartFields.push(f);
    }
  });

  // Note - Vle: The first field is considered the X axis by default
  var nameField = chartFields[0] ? chartFields[0].name : '?';
  chartFields.forEach(function (f) {
    if (f.name !== nameField) Ext.Array.push(dataField, f.name);
  });

  config = {
    background: '#fff',
    animate: true,
    height: height,
    width: width,
    shadow: true,
    theme: chartThemeStore.getAt(0).get('id'),
    axes: [
      {
        type: 'numeric',
        position: 'left',
        fields: dataField,
        label: {
          renderer: Ext.util.Format.numberRenderer('0,0'),
        },
        title: '',
        grid: true,
      },
      {
        type: 'category',
        position: 'bottom',
        fields: [nameField],
        title: nameField,
      },
    ],
    series: [],
  };

  dataField.forEach(function (f) {
    config.series.push({
      type: chartTypeStore.getAt(0).get('id'),
      axis: 'left',
      highlight: true,
      xField: nameField,
      yField: f,
      style: {
        opacity: 0.93,
      },
    });
  });

  return config;
};

export const renderComboBoxColumn = (
  value,
  _metaData,
  _record,
  _rowIdx,
  colIdx,
  _store,
  view
) => {
  const column = view.getColumnManager().getColumns()[colIdx];
  const editor = column.getEditor();
  const rec = editor ? editor.store.findRecord('id', value) : null;
  return rec ? rec.get(editor.displayField) : value;
};

export const runReportFromJSON = (
  jsonString,
  title = 'Quick Data View',
  vm,
  tbar
) => {
  if (vm && tbar) {
    enact(vm, tbar, false);
  }

  const final = () => {
    if (vm && tbar) {
      enact(vm, tbar, true);
    }
  };

  createWindow(
    `viewer-${index++}`,
    {
      title: title,
      Component: loadable(() => import(`views/Viewer`)),
      props: {
        config: {
          title,
          toolbar: {
            show: true,
            height: 36,
            color: '#e9327c',
            backgroundColor: '#FFF',
          },
        },
        jsonString,
        finalize: final,
      },
    },
    true
  );
};

export const getFilter = (r, index, reportCode) => {
  const filter = r.data ? r.data : r;
  let operator = filter.operator || '-';

  if (operator.toLowerCase() === 'wallet') {
    if (!filter.filterfrom /*|| !filter.filterfrom.startsWith('0x')*/) {
      if (appStore.wallet) {
        operator = '-';
        // filterto = filter.filterto.toLowerCase();
        filter.filterfrom = appStore.wallet.toLowerCase();
      } else {
        // filterto = filter.filterto.toLowerCase();
        warn('status.no_connected_wallet');
        throw 'status.no_connected_wallet';
      }
    } else {
      // filterto = filter.filterto.toLowerCase();
      filter.filterfrom = filter.filterfrom.toLowerCase();
    }
  } else {
    if (filter.datatype === 'D') {
      if (filter.node === 'raw_date_filter') {
        filter.filterto = getToDate(filter.filterto);
      } else {
        filter.filterfrom = getFromDate(filter.filterfrom);
        filter.filterto = getToDate(filter.filterto);
      }
    } else if (typeof filter.filterfrom === 'string') {
      filter.filterfrom = filter.filterfrom || '';
    }
  }

  return {
    ...filter,
    reportcode: reportCode,
    reportdetailid: index,
    isfilter: true,
    operator,
  };
};

export const getOutput = (r, index, reportCode, isCode) => {
  const output = r.data ? r.data : r;
  const {
    operator,
    filterfrom,
    filterto,
    isnot,
    isnot_checked,
    alias = '',
    expression = '',
    ...others
  } = output;

  const name = isCode === true ? /[^/;\\]*$/.exec(output.node)[0] : output.name;
  const arr = (alias || '').split(':');

  return {
    ...others,
    name,
    reportcode: reportCode,
    reportdetailid: index,
    isfilter: false,
    alias: arr.length > 1 ? arr[0] : alias,
    expression: arr.length > 1 ? arr[1] : expression,
  };
};

export const getChart = (r) => {
  const chart = r.data ? r.data : r;
  const { id, name, chartid, chartconfig, datarange } = chart;

  return {
    id,
    name,
    chartid,
    chartconfig,
    datarange,
  };
};

export const getJSONData = (recRPD, isCode) => {
  // Note: recRPD can be a Ext model or just raw data.
  // TODO: change all to raw data;
  if (!recRPD) {
    return null;
  }

  const reportDef = recRPD.data || recRPD;
  const reportCode = reportDef.reportcode;
  const reportFilters =
    typeof recRPD.filters === 'function' ? recRPD.filters() : recRPD.filters;
  const reportOutputs =
    typeof recRPD.outputs === 'function' ? recRPD.outputs() : recRPD.outputs;
  const reportCharts =
    typeof recRPD.charts === 'function' ? recRPD.charts() : recRPD.charts;
  const filters = [];
  const outputs = [];
  const charts = [];

  let reportdetailid = 0;
  let iterateFn =
    typeof reportFilters.each === 'function'
      ? reportFilters.each.bind(reportFilters)
      : reportFilters.forEach.bind(reportFilters);

  iterateFn((r) => {
    filters.push(getFilter(r, reportdetailid, reportCode));
    reportdetailid++;
  });

  iterateFn =
    typeof reportOutputs.each === 'function'
      ? reportOutputs.each.bind(reportOutputs)
      : reportOutputs.forEach.bind(reportOutputs);

  iterateFn((r) => {
    outputs.push(getOutput(r, reportdetailid, reportCode, isCode));
    reportdetailid++;
  });

  iterateFn =
    typeof reportCharts.each === 'function'
      ? reportCharts.each.bind(reportCharts)
      : reportCharts.forEach.bind(reportCharts);

  iterateFn((r) => {
    charts.push(getChart(r));
  });

  const query = {
    ...reportDef,
    filters,
    outputs,
    charts,
  };

  return query;
};

export const getDatasetQuery = (recRPD) => {
  const json = getJSONData(recRPD);
  const { id, reportcode, name, owner, datasource, ledger, filters, outputs } =
    json;

  const queryJson = {
    from: datasource,
    select: outputs.map((o) => ({
      node: o.node,
      name: o.name,
      alias: o.alias,
      datatype: o.datatype,
      aggregate: o.aggregate,
      expression: o.expression,
      sorting: o.sorting,
    })),
    where: filters.map((f) => ({
      node: f.node,
      name: f.name,
      alias: f.alias,
      datatype: f.datatype,
      filterfrom: f.filterfrom,
      filterto: f.filterto,
      isnot: f.isnot,
      operator: f.operator,
    })),
  };

  const queryString = `USER TABLE(QUERY_DB(${JSON.stringify(
    queryJson
  )},${reportcode}))`;

  return queryString;
};

export const getDrilldownQuery = (recRDP) => {
  const json = getJSONData(recRDP);
  const { id, reportcode, name, owner, datasource, ledger, filters, outputs } =
    json;

  const queryJson = {
    from: datasource,
    select: outputs.reduce((acc, o) => {
      if (o.aggregate) {
        acc.push({
          node: o.node,
          name: o.name,
          alias: o.alias,
          datatype: o.datatype,
          aggregate: o.aggregate,
          // expression: o.expression,
        });
      }
      return acc;
    }, []),
    where: filters.map((f) => ({
      node: f.node,
      name: f.name,
      alias: f.alias,
      datatype: f.datatype,
      filterfrom: f.filterfrom,
      filterto: f.filterto,
      isnot: f.isnot,
      operator: f.operator,
    })),
  };

  const queryString = `DRILLDOWN(QUERY_DB(${JSON.stringify(
    queryJson
  )},${reportcode}))`;

  return queryString;
};

export const getReportQuery = (recRDP) => {
  const json = getJSONData(recRDP);
  const { id, reportcode, name, owner, datasource, ledger, filters, outputs } =
    json;

  const queryJson = {
    from: datasource,
    select: outputs.map((o) => ({
      node: o.node,
      name: o.name,
      alias: o.alias,
      datatype: o.datatype,
      aggregate: o.aggregate,
      expression: o.expression,
      sorting: o.sorting,
    })),
    where: filters.map((f) => ({
      node: f.node,
      name: f.name,
      alias: f.alias,
      datatype: f.datatype,
      filterfrom: f.filterfrom,
      filterto: f.filterto,
      isnot: f.isnot,
      operator: f.operator,
    })),
  };

  const queryString = `REPORT(QUERY_DB(${JSON.stringify(
    queryJson
  )},${reportcode}))`;

  return queryString;
};

export const createMatchedJournalLine = (records, posting = 'B') => {
  const newRec = records[0].copy(null);
  const journalsource = localStorage.getItem('operatorid');

  Object.assign(newRec.data, {
    journalno: -1,
    journalline: 1,
    transactionref: '+',
    description: '',
    accountcode: '',
    journalsource,
    splitorigline: -1,
    allocation: 'A',
    allocationref: '',
    currencyrate: 0,
    operator: '',
    otheramt: 0,
    reportrate: 0,
    reportoperator: '',
    reportamt: 0,
    inuseflag: 'Y',
    posting,
  });

  const contraAccounts = [];
  let amount = 0;
  let oAmount = 0;
  records.forEach((rec) => {
    if (rec.data.d_c === 'D') {
      amount += rec.data.amount;
      oAmount += rec.data.otheramt;
    } else {
      amount -= rec.data.amount;
      oAmount -= rec.data.otheramt;
    }

    for (let i = 0; i < 10; i++) {
      const anlys_t = `annal_t${i}`;
      if (newRec.get(anlys_t) && newRec.get(anlys_t) !== rec.get(anlys_t)) {
        newRec.set(anlys_t, '');
      }

      if (i < 5) {
        const date = `date${i}`;
        const number = `number${i}`;
        const reference = `reference${i}`;
        const description = `description${i}`;
        if (newRec.get(date) && newRec.get(date) !== rec.get(date)) {
          newRec.set(date, null);
        }
        if (newRec.get(number) !== rec.get(number)) {
          newRec.set(number, 0);
        }
        if (
          newRec.get(reference) &&
          newRec.get(reference) !== rec.get(reference)
        ) {
          newRec.set(reference, '');
        }
        if (
          newRec.get(description) &&
          newRec.get(description) !== rec.get(description)
        ) {
          newRec.set(description, '');
        }
      }
    }

    if (!contraAccounts.includes(rec.data.accountcode)) {
      contraAccounts.push(rec.data.accountcode);
    }
  });

  if (amount > 0) {
    newRec.data.amount = amount;
    newRec.data.otheramt = oAmount;
    newRec.data.d_c = 'D';
    newRec.data.creditamount = 0;
    newRec.data.debitamount = newRec.data.amount;
  } else {
    newRec.data.amount = -1 * amount;
    newRec.data.otheramt = -1 * oAmount;
    newRec.data.d_c = 'C';
    newRec.data.debitamount = 0;
    newRec.data.creditamount = newRec.data.amount;
  }
  newRec.data.contraaccount = contraAccounts.join(',');

  return newRec;
};
