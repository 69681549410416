import { Box, Typography, Container, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoginIcon from '@mui/icons-material/Login';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Typography className={classes.title} align="center" variant="h4">
          {t('home.title')}
        </Typography>
        <Typography
          className={classes.intro}
          align="justify"
          variant="body1"
          component="p"
        >
          {t('home.intro1')}
        </Typography>
        <Typography
          className={classes.intro}
          align="justify"
          variant="body1"
          component="p"
        >
          <span className={classes.knowins}>KNOWINS</span>
          {t('home.intro2')}
        </Typography>
        <Typography
          className={classes.intro}
          align="justify"
          variant="body1"
          component="p"
        >
          {t('home.intro3')}
          <span className={classes.knowins}>KNOWINS</span>
          {t('home.intro4')}
        </Typography>
        <Typography
          className={classes.intro}
          align="justify"
          variant="body1"
          component="p"
        >
          <span className={classes.knowins}>KNOWINS</span>
          {t('home.intro5')}
        </Typography>

        <Box
          sx={{
            mt: 8,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            className={classes.button}
            size="large"
            component={Link}
            to="/login"
            startIcon={<LoginIcon />}
          >
            {t('login.title')}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    // padding: theme.spacing(12, 4),
    textAlign: 'center',
    padding: theme.spacing(16, 0, 12, 0),
    backgroundImage: `url("background.jpeg")`,
    // backgroundSize: 'cover',
    backgroundRepeat: 'round',
  },
  hightligh: {
    fontWeight: 700,
    fontSize: theme.spacing(5),
    color: '#e9327c',
  },
  knowins: {
    fontSize: theme.spacing(5),
    color: '#e9327c',
    fontWeight: 550,
  },
  title: {
    color: 'white',
    lineHeight: 1.1,
    // color: '#e9327c',
    fontWeight: 700,
    fontSize: 28,
  },
  intro: {
    color: 'white',
    fontWeight: 550,
    fontSize: theme.spacing(5),
    padding: theme.spacing(3, 0, 3, 0),
  },
  button: {
    // backgroundColor: '#e9327c',
    fontSize: theme.spacing(3),
    padding: theme.spacing(3, 3),
  },
}));

export default Home;
