// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { proxy, useSnapshot } from 'valtio';
import axios from 'redaxios';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Person3Sharp';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/SendSharp';

import { Typography, Link, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import Paper, { PaperProps } from '@mui/material/Paper';
import { Rnd } from 'react-rnd';

import store, { getChat } from './store/ChatStore';
import { invite, text } from './hook/useInitialization';
import { executePromise } from '@knowins/notifier';

const room = 'public';
const personal = 'private';
const internal = 'internal';

const defaultSize = {
  width: 600,
  height: window.innerHeight - 74,
};

const defaultPositionFn = () => {
  return {
    x: window.innerWidth - defaultSize.width - 13,
    y: window.innerHeight - defaultSize.height - 68,
  };
};

export default function DraggableDialog() {
  const { t } = useTranslation();
  const messageEl: React.MutableRefObject<{
    current: {
      addEventListener: (evt: string, handler: (e: any) => void) => void;
    };
  } | null> = useRef(null);
  const state = useSnapshot(store);

  useEffect(() => {
    if (messageEl && messageEl.current) {
      messageEl.current?.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, []);

  const myName =
    localStorage.getItem('operatorname') ||
    localStorage.getItem('moneta') ||
    '';
  const wallet = localStorage.getItem('wallet');
  const authorAccount = `eip155:1:${wallet}`;

  const chatters = state.session === personal ? state.private : [];
  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      address: store.current,
      message: '',
    },
  });

  const isOutgoingMessage = (account: string) => account === authorAccount;

  const goToPublicRoom = () => {
    console.log('go to public');
    store.session = room;
  };

  const goToPrivatecRoom = () => {
    console.log('go to personal');
    store.session = personal;
  };

  const changeChatter = (address) => {
    store.current = address;
    setValue('address', address);
  };

  const onSubmit = async (data: { address: string; message: string }) => {
    const { address, message } = data;
    if (message.length === 0) {
      return;
    }

    if (state.session === personal) {
      const chat = getChat(address);
      if (chat && store.private[chat].accepted) {
        //const chatmates = setValue('message', '');
        const topic = store.private[chat].topic;
        console.log('in chat with.....', chat, topic);

        store.private[chat].payloads = [
          ...store.private[chat].payloads,
          {
            timestamp: Date.now(),
            message,
            authorAccount,
          },
        ];

        text(topic, message);
      } else {
        executePromise(
          axios.post('/api/router.php', {
            action: 'FrmUser',
            method: 'getUserWallet',
            data: [{ address }],
            type: 'rpc',
          }),
          {
            loading: 'status.sending_request',
            success: (res) => {
              console.log('res..........', res);
              const { moneta: chat, wallet, fullname, email } = res.data;
              invite(wallet, myName, message).then((id) => {
                console.log('invite id.......', id);
                store.private[chat] = {
                  // invitation_id: id,
                  email,
                  fullname: fullname || chat,
                  // topic: '', topic is NOT available yet
                  adddress: `eip155:1:${wallet}`,
                  payloads: [{ timestamp: Date.now(), message, authorAccount }],
                };
                store.current = chat;
              });
              return 'status.sent_chat_invitation';
            },
          }
        );
      }
    }
    setValue('message', '');
  };
  const activeChat =
    state.session === personal ? state.private[state.current] : undefined;

  return (
    <Rnd
      default={{
        ...defaultPositionFn(),
        width: defaultSize.width,
        height: defaultSize.height,
      }}
      dragHandleClassName="drag-handle"
    >
      <form
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card
          style={{
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
          }}
        >
          <CardHeader
            style={{
              cursor: 'move',
              padding: '0 16px',
              height: 60,
              bgcolor: 'background.paper',
            }}
            className="drag-handle"
            subheader={
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ width: 268 }}
                    margin="dense"
                    placeholder={t('chat.receiver')}
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ChatIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            }
          ></CardHeader>
          <CardContent sx={{ p: '0 16px', height: 'calc(100% - 120px)' }}>
            <div
              style={{ display: 'flex', flexFlow: 'column', height: '100%' }}
            >
              {state.session === personal && (
                <Grid sx={{ m: 0, p: 0, height: 68 }} container spacing={1}>
                  {Object.keys(chatters).map((k) => {
                    return (
                      <Grid
                        key={k}
                        xs
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <IconButton
                          sx={{ display: 'block' }}
                          color="secondary"
                          size="small"
                          onClick={() => changeChatter(k)}
                        >
                          <Avatar
                            alt={k.toUpperCase()}
                            src={'/api/get/avatar.php?moneta=' + k}
                            style={{
                              backgroundColor:
                                state.current === k ? '#e9327c' : '#edf2ff',
                            }}
                          />
                          <Typography color="primary" sx={{ lineHeight: 0.6 }}>
                            {k}
                          </Typography>
                        </IconButton>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
              <Paper
                variant="outlined"
                style={{ flex: 1, overflow: 'auto' }}
                ref={messageEl}
                sx={{
                  borderLeft: '1px solid red',
                }}
              >
                {activeChat && (
                  <List
                    sx={{
                      height: '100%',
                      bgcolor: 'background.paper',
                    }}
                  >
                    {activeChat.payloads.map((p, idx) => (
                      <React.Fragment key={`${p.timestamp}-${idx}`}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            borderBottom:
                              activeChat.payloads[idx + 1] === undefined
                                ? 0
                                : activeChat.payloads[idx + 1]
                                    ?.authorAccount === p.authorAccount
                                ? 0
                                : '0.5px solid #e6eaef',
                          }}
                        >
                          <ListItemText
                            primary={
                              idx > 0 &&
                              p.authorAccount ===
                                activeChat.payloads[idx - 1]?.authorAccount
                                ? undefined
                                : isOutgoingMessage(p.authorAccount)
                                ? myName
                                : activeChat.fullname
                            }
                            primaryTypographyProps={{
                              sx: {
                                fontSize: 13,
                                lineHeight: 0.8,
                                fontWeight: 700,
                                marginBottom: 2,
                              },
                            }}
                            secondary={p.message}
                            secondaryTypographyProps={{
                              component: 'span',
                              sx: {
                                fontSize: 13,
                                lineHeight: 0.8,
                                paddingTop: 0,
                                paddingBottom: 0,
                                textAlign: isOutgoingMessage(p.authorAccount)
                                  ? 'end'
                                  : 'start',
                              },
                            }}
                          />
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </Paper>
              <div style={{ height: 53 }}>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      margin="dense"
                      fullWidth
                      label={t('chat.message')}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton size="small" type="submit">
                              <SendIcon color="primary" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </CardContent>
          <CardActions sx={{ height: 60 }}>
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={3}
            >
              <BottomNavigation
                showLabels
                // value={value}
                // onChange={(event, newValue) => {
                //   setValue(newValue);
                // }}
              >
                <BottomNavigationAction
                  // disabled
                  label="Public"
                  icon={<RestoreIcon color="secondary" />}
                  onClick={goToPublicRoom}
                />
                <BottomNavigationAction
                  label="Private"
                  icon={<FavoriteIcon color="primary" />}
                  onClick={goToPrivatecRoom}
                />
                <BottomNavigationAction
                  disabled
                  label="Team"
                  icon={<ArchiveIcon color="secondary" />}
                />
              </BottomNavigation>
            </Paper>
          </CardActions>
        </Card>
      </form>
    </Rnd>
  );
}
