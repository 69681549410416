import { useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/LoginSharp';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import store from './store';

const MetaMask = (props: any) => {
  const { t } = useTranslation();

  const state = useSnapshot(store);

  const handleClose = () => {
    store.open = false;
  };

  const handleSelect = (data) => {
    store.selected = data.moneta;
  };

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: 'moneta',
        headerName: t('wallet_connect.org'),
        flex: 1,
        // headerClassName: {},
      },
      {
        field: 'fullname',
        headerName: t('wallet_connect.fullname'),
        flex: 3,
      },
      {
        field: 'action',
        headerName: '',
        width: 68,
        renderCell: (params: any) => (
          <IconButton
            aria-label="login"
            onClick={() => handleSelect(params.row)}
          >
            <LoginIcon />
          </IconButton>
        ),
      },
    ],
    [t]
  );

  if (!state.open || state.authorization.length === 0) {
    return null;
  }

  return (
    <Dialog onClose={handleClose} open={state.open} fullWidth>
      <DialogTitle sx={{ m: 0, p: 0, backgroundColor: '#e9327c' }}>
        <Grid xs display="flex" justifyContent="center" alignItems="center">
          <Typography sx={{ lineHeight: 2.8 }} color="secondary">
            {props.title}
          </Typography>
        </Grid>
      </DialogTitle>
      <Box width="100%" height="100%">
        <Box
          sx={{
            flex: '1 1 0%',
            height: 280,
          }}
        >
          <DataGrid
            rows={state.authorization?.map((r) => ({
              ...r,
              id: r.moneta,
            }))}
            columns={columns}
            autoPageSize
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default MetaMask;
